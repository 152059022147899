const encodedAndCleanFileName =(file_name) =>  { 
    if(file_name) { 
        let encodedFileName = encodeURI(file_name);
        let encodedAndCleanFileName = encodedFileName.replace(/#(?=\S)/g, '%23')
        return encodedAndCleanFileName;
    }
    return null;
}
export default {
    encodedAndCleanFileName
}