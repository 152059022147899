<template>
    <CModal
        size="xl"
        :show.sync="modal_form"
        color="primary"
        :closeOnBackdrop="false"
    >
        <template #header>
            <h5> <font-awesome-icon icon="link"/> Upload File Via URL </h5>
            <CButton 
                class="pull-right" 
                color="light" 
                shape="pill" 
                size="sm"
                @click="modal_form = false"
            >
                <font-awesome-icon icon="times"/>
            </CButton>
        </template>
        <template #body-wrapper>
            <CCardBody>
                <h5>Please fill-up the form.</h5>
                <br>
                <template v-if="modal_form">
                    <CRow>
                        <CCol lg="4" v-for="(element, index) in template.document_template_file_api.document_template_file_api_params" v-bind:key="index">
                            
                            <CInput 
                                v-if="element.type == 'input'"
                                :disabled="document_found"
                                :label="element.parameter"
                                :type="element.type" 
                                :placeholder="element.parameter" 
                                autocomplete="off"  
                                v-model="file_via_url_field[index]"
                                :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-inp-error' : 'has-inp-success' : ''"
                            />
                            <CTextarea
                                :label="element.parameter"
                                v-if="element.type == 'textarea'"
                                :disabled="document_found"
                                :placeholder="element.parameter" 
                                autocomplete="off"  
                                v-tocapitalize
                                v-model="file_via_url_field[index]"
                                :class="element.is_required == '1' ? !file_via_url_field[index] ? 'has-txtarea-error grp-text-area' : 'has-txtarea-success grp-text-area' : 'grp-text-area'"
                            />
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="4">
                            <CButton
                                class="float-left"  
                                :disabled="form_submitting || document_found"
                                color="primary" 
                                @click="getDocumentfromURL()">
                                <font-awesome-icon 
                                    class="icon" :icon="!form_submitting ? 'file' : 'circle-notch'"
                                    :pulse="form_submitting"/> 
                                    Get Document
                            </CButton>
                        </CCol>
                        <CCol lg="8"></CCol>
                    </CRow>
                </template>
                <template v-if="document_found">
                    <br>
                    <CRow>
                        <CCol lg ="8"/>
                        <CCol lg ="2">
                            <h5 style="margin-top:7px;" class="float-right"> Page</h5>
                        </CCol>
                        <CCol lg="1">
                            <CInput
                                type="number" 
                                autocomplete="off"  
                                min="1" :max="numPages"
                                v-model.number="page"/>
                        </CCol>
                        <CCol lg="1">
                            <h5 style="margin-top:7px;" class="float-left"> / {{numPages}} </h5>
                        </CCol>
                    </CRow>
                    <CRow>
                        <CCol lg="1">
                            <CButton color="primary" style="margin-top: 500%;" @click="page--" :disabled="page == 1">
                                <font-awesome-icon icon="angle-double-left"/>
                            </CButton>
                        </CCol>
                        <CCol lg="10">
                            <Vue-Pdf
                                :page="page" 
                                @num-pages="numPages = $event" 
                                :src="fetch_document.file"
                            />
                        </CCol>
                        <CCol lg="1">
                            <CButton color="primary" style="margin-top: 500%;" @click="page++" :disabled="page==numPages">
                                <font-awesome-icon icon="angle-double-right"/>
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
                <br>
                <template v-if="document_found">
                    <CRow>
                        <CCol lg="8"></CCol>
                        <CCol lg="2">
                            <CButton 
                                @click="clearDocument()"
                                color="secondary" 
                                class="float-right">
                                <font-awesome-icon class="icon" icon="times" /> 
                                    Clear Document
                            </CButton>
                        </CCol>
                        <CCol lg="2" >
                            <CButton
                                @click="confirmDocument()"
                                color="primary" class="float-right">
                                <font-awesome-icon class="icon" icon="save" /> 
                                    Confirm Document
                            </CButton>
                        </CCol>
                    </CRow>
                </template>
            </CCardBody>
        </template>
        <template #footer>
            <hr hide>
        </template>
    </CModal>
</template>
<script>
export default {
    name: 'UploadFileViaURLModal',
    data() {
        return {
            page: 1,
            numPages: 0,
            
            can_upload_file_via_url: false,
            modal_form: false,

            form_submitting: false,
            document_found: false,
            file_via_url_field:[],
            fetch_document: null,

            template: null,

        }
    },
    methods: {
        validateForm: function(){
            let required_fields = []
            for (let i = 0; i < this.template.document_template_file_api.document_template_file_api_params.length; i++) {
                const element = this.template.document_template_file_api.document_template_file_api_params[i];
                if(element.is_required ==  '1' && !this.file_via_url_field[i]){
                    required_fields.push(element.parameter);
                }
            }

            if( required_fields.length > 0 ) {
                return false
            }
            return true;
        },
        getDocumentfromURL: function () {
            if(!this.validateForm()) {
                return this.$swal({
                    toast: true,
                    position: 'top-right',
                    timer: 3000,
                    icon: 'error',
                    title: `Please fill-up the required fields.`,
                    showConfirmButton: false,
                    timerProgressBar: true,
                })
            }


            this.form_submitting = true

            var columns = this.template.document_template_file_api.document_template_file_api_params
            var rows = this.file_via_url_field
            var parameters = columns.reduce(function (parameters, field, index) {
                    parameters[field.parameter] = rows[index] ? rows[index] : null;
                    return parameters;
                }, {})

            let form = {
                document_template_id: this.template.id,
                document_template_file_api_id: this.template.document_template_file_api.id,
                parameters: parameters
            }

            this.$Progress.start()
            axios.post('/drs/document-template-file-api/get-document', form, {validateStatus: () => true})
                .then(response => {
                    this.$Progress.finish()
                    if ( response.status == 200 ) {
                        this.form_submitting = false
                        this.document_found = true;
                        this.fetch_document = response.data.data
                        this.fetch_document.file = `${this.$backend_url_with_auth}/file-viewer/tmp/${this.fetch_document.path}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`
                        this.$swal({
                            toast: true,
                            position: 'top-right',
                            timer: 3000,
                            icon: 'success',
                            title: 'Document Found.',
                            showConfirmButton: false,
                            timerProgressBar: true,
                        })
                        return; 
                    }
                    this.form_submitting = false
                })
        },
        clearDocument: function () {
            this.fetch_document = null
            this.document_found = false;
        },
        confirmDocument: function (){
            let file = {
                size: this.fetch_document.file_size,
                name: this.fetch_document.reference_number+'.pdf',
                type: "application/pdf",
                url: `${this.$backend_url_with_auth}/file-viewer/tmp/${this.fetch_document.path}?token=${this.$store.getters.getUserToken}&org_key=${this.$store.getters.getOrganizationKey}`,
                response_url: this.fetch_document.path

            }
            let object = {
                file: file,
                reference_number: this.fetch_document.reference_number
            }
            this.modal_form = false
            this.$emit('documentFoundViaURLConfirmed', object)
        },
    },
    watch: {
        modal_form: function (value){
            if(!value) {
                this.file_via_url_field = []
                this.document_found = false
                this.fetch_document= null
                this.form_submitting = false
            }
        }
        
    },
     
}
</script>